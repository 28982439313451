import { Formik } from 'formik';
import { usePathname } from 'next/navigation';

// styles
import styles from './cancellation.module.scss';
import layoutStyles from 'scenes/Campus/layout.module.scss';

// types
import { Course, CourseDateList } from '../types';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import { Translation } from 'components/Translation/Translation';
import { Icon } from 'components/Icon/Icon';
import { InternalLink } from 'components/Link/InternalLink';
import ParticipantsForm from './ParticipantsForm';
import { IntroText } from 'components/ContentElementsGdds/IntroText/intro-text';
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { getMetatag } from '../metatags';
import { useCampusEnv, useCampusTypes } from 'utils/hooks/useCampus';
import { useDateFormat } from 'utils/hooks/use-dateformat';
import { useIsMobile } from 'components/App/SizeProvider';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';
import { classNameBuilder } from 'utils/classNameBuilder';

interface CancellationProps {
  courseId: number;
  data: Course;
  reloadDetails: () => void;
}

export function Cancellation({ reloadDetails, courseId, data }: Readonly<CancellationProps>) {
  const { CAMPUS_META } = useCampusEnv();
  const pathname = usePathname();
  const [type, icon] = useCampusTypes(data?.topics);
  const translate = useTranslationFunction();
  const isMobile = useIsMobile();

  const metaTags = data?.metaTags;
  const duration = getMetatag(CAMPUS_META.DURATION, metaTags);
  const dateStart = getMetatag(CAMPUS_META.START_DATE, metaTags, true);
  const dateEnd = getMetatag(CAMPUS_META.END_DATE, metaTags, true);
  const courseDates: CourseDateList = [];
  const dateFormat = useDateFormat();

  if (dateStart) {
    const dateObj = new Date(Date.parse(dateStart));
    const timeString = dateStart.split(' ')[1].slice(0, 5);

    let dateString = '';
    dateFormat?.split('.').forEach((x, index) => {
      switch (x) {
        case 'dd':
          dateString += `0${dateObj.getDate()}`.slice(-2);
          break;
        case 'MM':
          dateString += `0${dateObj.getMonth() + 1}`.slice(-2);
          break;
        case 'yyyy':
          dateString += dateObj.getFullYear();
          break;
        default:
          dateString += x;
      }
      if (index < 2) {
        dateString += '.';
      }
    });
    courseDates.push({
      day: dateString,
      time: timeString,
    });
  }

  if (dateEnd) {
    const dateObj = new Date(Date.parse(dateEnd));
    const timeString = dateEnd.split(' ')[1].slice(0, 5);
    let dateString = '';
    dateFormat?.split('.').forEach((x, index) => {
      switch (x) {
        case 'dd':
          dateString += `0${dateObj.getDate()}`.slice(-2);
          break;
        case 'MM':
          dateString += `0${dateObj.getMonth() + 1}`.slice(-2);
          break;
        case 'yyyy':
          dateString += dateObj.getFullYear();
          break;
        default:
          dateString += x;
      }
      if (index < 2) {
        dateString += '.';
      }
    });
    courseDates.push({
      day: dateString,
      time: timeString,
    });
  }

  const initialValues = { names: [] };
  return (
    <div className={styles.cancelCourse}>
      <div className="back-to-course">
        <InternalLink
          content={{ target: `${pathname.split('#')[0]}`, showArrow: true }}
          className="web20-icon-arrow-link-light-left"
        >
          <Translation id="campus_back_course" />
        </InternalLink>
      </div>

      <IntroText
        title={translate('campus_headline_course_cancellation')}
        subtitle={translate('campus_subline_course_cancellation')}
        intro={translate('campus_text_cancellation')}
        text=""
        border="disable-border"
        emphasize="title"
        containerClassName={layoutStyles.campusIntroTextContainer}
        disableInEdit
        isFlexItem
      ></IntroText>

      <div className={styles.courseTeaserWrapper}>
        <Headline
          title={translate('campus_headline_course')}
          className={styles.headline}
          tag={Formats.h3}
          isFlexItem
        />

        <div className={styles.courseTeaser}>
          <div className={classNameBuilder('course-icons', type)}>
            <span>
              <Icon symbol={icon} />
            </span>
          </div>
          <div className="course-inner-wrapper">
            <div className={styles.courseHeadlines}>
              <div className="type">{data?.learningForm}</div>
              <Headline
                title={getMetatag(CAMPUS_META.NAME, metaTags)}
                tag={Formats.h4}
                isFlexItem
              />
            </div>
            <div className="course-information">
              {(type === 'webinar' || type === 'seminar') && !isEmpty(courseDates) && (
                <div className="item">
                  <Icon symbol="course-date" />
                  <span>
                    <b>
                      <Translation id="campus_date" />
                    </b>
                    <div className={styles.itemContent}>
                      {courseDates.map((item) => (
                        <span key={item?.day}>
                          {item?.day}
                          {item?.time && (
                            <>
                              <br />
                              {item.time}
                            </>
                          )}
                        </span>
                      ))}
                    </div>
                  </span>
                </div>
              )}
              {(type === 'elearning' || type === 'webcast') && duration && (
                <div className="item">
                  <Icon symbol="user" />
                  <span>
                    <b>
                      <Translation id="campus_duration" />
                    </b>
                    <div className="item-content">
                      <span key={duration}>{duration}</span>
                    </div>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.participants}>
        <Formik onSubmit={() => {}} initialValues={initialValues}>
          {(props) => (
            <ParticipantsForm
              isMobile={isMobile}
              reloadDetails={reloadDetails}
              courseId={courseId}
              {...props}
            />
          )}
        </Formik>
      </div>
    </div>
  );
}
