import { Container } from '@geberit/gdds';

// components
import Footer from 'components/Footer/Footer';

// utils
import { useThemeName } from 'utils/hooks/use-theme';

// constants
import { THEME_NAMES } from 'themes';

interface CampusLayoutProps extends React.PropsWithChildren {
  noErrorBoundary?: boolean;
}

export default function CampusLayout({ children }: Readonly<CampusLayoutProps>) {
  const themeName = useThemeName() ?? THEME_NAMES.DEFAULT;

  let maxContentWidth = '90rem';
  if (themeName === 'DEFAULT') {
    maxContentWidth = '80rem';
  }

  return (
    <>
      <Container maxContentWidth={maxContentWidth}>
        <main>{children}</main>
      </Container>
      <Footer isCampus />
    </>
  );
}
