import { useContext } from 'react';
import { Row, Col } from '@geberit/gdds';

// styles
import { LocatorContainer } from './showroom-locator.styles';

// components
import { GoogleMapProvider } from './google-map-provider';
import { FilterContextProvider, MapWrapper, RefsContextProvider } from '../map';
import { SearchContext } from '../search';
import { LocatorConsent } from '../consent';
import { ZipEntry } from '../zip-entry';
import { ContentArea } from 'components/ContentArea/ContentArea';

// types
import type { IEntry } from '../locator.types';
import type { ContentElementPayload } from 'components/ContentElementsGdds/content-elements-payload';

// utils
import { useLocatorConfiguration } from '../utils/use-locator-configuration';
import { PinAndListInteractionProvider } from '../map/providers/pin-and-list-interaction-provider';
import { useUCConsent } from 'features/cookie-consent';

interface ShowroomLocatorProps {
  page: {
    headline: string;
    subHeadline: string;
  };
  entries: IEntry[];
  sortStructure: string[];
  content: ContentElementPayload[];
}

export function LocatorOverviewPage({
  page: { headline, subHeadline },
  entries,
  sortStructure,
  content = [],
}: Readonly<ShowroomLocatorProps>) {
  const entriesWithIndex = entries.map((entry, index) => {
    return { ...entry, index: index };
  });
  const consent = useUCConsent();
  const locatorConsent = useLocatorConfiguration();
  const { location } = useContext(SearchContext);
  const showConsent = consent.GoogleMaps === false && locatorConsent !== undefined;
  const showZipEntry = consent.GoogleMaps === true && !location;
  const showMap = consent.GoogleMaps === true && !showZipEntry;

  return (
    <>
      {showConsent && <LocatorConsent {...locatorConsent?.consent} />}
      {!showConsent && (
        <GoogleMapProvider>
          <LocatorContainer>
            <Row>
              <Col>
                <FilterContextProvider
                  entries={entriesWithIndex}
                  sortStructure={sortStructure}
                  location={location}
                >
                  {showZipEntry && <ZipEntry headline={headline} subHeadline={subHeadline} />}
                  {showMap && (
                    <RefsContextProvider>
                      <PinAndListInteractionProvider>
                        <MapWrapper />
                      </PinAndListInteractionProvider>
                    </RefsContextProvider>
                  )}
                </FilterContextProvider>
              </Col>
            </Row>
          </LocatorContainer>
        </GoogleMapProvider>
      )}
      {showMap && <ContentArea content={content} contentType="content" />}
    </>
  );
}
