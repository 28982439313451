import { useRef, useEffect, useState, useId } from 'react';

// styles
import styles from './videocenter-channel.module.scss';

// types
import type { VideoLightboxProps as DialogueBodyProps } from './types';

// components
import SocialIcons from 'components/ContentElementsGdds/PressPortal/social-icons/social-icons';
import { MiVideoContainer } from 'components/Video/miVideo/MiVideoContainer';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { CopyToClipboard } from 'components/copy-to-clipboard/copy-to-clipboard';

// utils
import { trackMIVideo } from 'components/Video/miVideo/trackingActions';
import { useUCConsent } from 'features/cookie-consent';
import { getStringSpecificId } from 'utils/slug';
import { useTracking } from 'utils/hooks/useTracking';
import { useTracking as useTracking2 } from 'utils/tracking/track';

export function DialogueBody({
  videoId,
  isYoutubeVideo,
  text,
  metaData,
  title,
  sharePageText,
}: Readonly<DialogueBodyProps>) {
  const [hasVideoError, setHasVideoError] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const ucConsent = useUCConsent();
  const mounted = useRef(false);
  const videoTrackRef = useRef(0);
  const ga4VideoTrackRef = useRef(0);
  const videoRef = useRef();
  const generatedId = useId();
  const id = getStringSpecificId('video-content', videoId, generatedId);
  const track = useTracking();
  let videoPlayerWindowObject;
  const { trackMI24Video } = useTracking2();

  useEffect(() => {
    mounted.current = true;

    if (ucConsent.Comfort === true) {
      // check if mi video script is already given

      const mi24Script = document.getElementById('mi24-js-script');
      if (!isYoutubeVideo && !mi24Script) {
        const script = document.createElement('script');
        script.src = `https://e.video-cdn.net/v2/embed.js`;
        script.id = 'mi24-js-script';
        script.async = true;
        script.addEventListener('load', () => {
          script.setAttribute('data-loaded', 'true');
          if (mounted.current) setVideoReady(true);
        });
        document.head.appendChild(script);
      } else if (!isYoutubeVideo && mounted.current && mi24Script) {
        if (mi24Script.getAttribute('data-loaded')) {
          setVideoReady(true);
        } else {
          mi24Script.addEventListener('load', () => {
            if (mounted.current) setVideoReady(true);
          });
        }
      }
    }

    return () => {
      mounted.current = false;
    };
  }, [ucConsent, isYoutubeVideo]);

  const handleClose = () => {
    if (typeof window !== 'undefined' && videoPlayerWindowObject && !isYoutubeVideo) {
      window.VideoPlayer.Collection.removePlayerById(id);
      videoPlayerWindowObject = false;
    }
  };

  /**
   * Init mi24 video
   */
  const initVideo = () => {
    if (!isYoutubeVideo) {
      if (window.VideoPlayer && !videoPlayerWindowObject && videoRef.current) {
        videoPlayerWindowObject = true;

        try {
          window.VideoPlayer.Collection.addPlayerById(id, {
            success: (playerApi) => {
              trackMIVideo(videoTrackRef, playerApi, title, videoId, track);
              trackMI24Video(ga4VideoTrackRef, playerApi, title, videoId);
            },
          });
        } catch {
          setHasVideoError(true);
        }
      }
    }
  };

  useEffect(() => {
    initVideo();

    return () => {
      handleClose();
    };
  }, []);

  if (!ucConsent.Comfort) {
    return null;
  }

  return (
    <div className={styles.lightboxContainer}>
      {videoReady && (
        <MiVideoContainer
          hasError={hasVideoError}
          isModal
          className="c-text-video"
          content={{ video: { videoId } }}
        />
      )}
      {text && <InnerHtml className={styles.copyText} as="p" content={text} isGdds />}
      <InnerHtml className={styles.shareText} as="p" content={sharePageText} isGdds />
      {metaData && (
        <>
          <SocialIcons
            socialNetworks={['facebook', 'linkedin', 'instagram', 'youtube', 'service-mail']}
            metaData={metaData}
            contentType="video"
          />
          <CopyToClipboard text={metaData.url} />
        </>
      )}
    </div>
  );
}
