'use client';
import { useState, useEffect, useContext } from 'react';
import { useSelector, useStore } from 'react-redux';
import { format } from 'date-fns';
import { Accordion } from 'react-accessible-accordion';
import { Container, Row, Col } from '@geberit/gdds';

// styles
import styles from './orderhistory.module.scss';

// types
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';
import type { Order } from './order-history.types';

// components
import { Title } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import { ProductDetailAccordionItem } from 'components/Product/DetailAccordion/ProductDetailAccordionItem';
import LoadMoreButton from 'components/LoadMoreButton/LoadMoreButton';
import { Translation } from 'components/Translation/Translation';
import { Message } from 'components/Message/Message';
import { Loader } from 'components/Loader/Loader';
import { Headline } from 'components/ContentElementsGdds/headline/headline';
import OrderHistoryDetails from './OrderHistoryDetails';

// utils
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';
import { fetchOrders } from './actions';
import { SessionContext } from 'components/Session/SessionContext';
import { useDateFormat } from 'utils/hooks/use-dateformat';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useXy } from 'utils/hooks/use-xy';
import { GddsOrdersAccordion } from './GddsOrdersAccordion';
import { buildSize, gridSizes } from 'utils/gridSize';

// Elements per page
const PER_PAGE = 10;

function OrderHistoryContainer() {
  const [page, setPage] = useState(0);
  const state = useStore();
  const [pagedItems, setPagedItems] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [hasLoadMore, setHasLoadMore] = useState(false);
  const dateFormat = useDateFormat();

  const isXy = useXy();
  const language = useSelector(currentCatalogSelector);
  const translate = useTranslationFunction();

  const {
    state: { ciam, sessionIsLoading },
  } = useContext(SessionContext);

  const getOrders = async () => {
    setIsLoading(true);
    try {
      const { hasMore, orders } = await fetchOrders(language, ciam?.UID, page, PER_PAGE, state);
      setHasLoadMore(hasMore);
      if (page === 0) {
        setPagedItems([...orders]);
      } else if (page > 0) {
        setPagedItems([...pagedItems, ...orders]);
      }

      setIsLoading(false);
    } catch (e) {
      setHasError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (ciam && !sessionIsLoading) {
      getOrders();
    }
    if (!ciam && !sessionIsLoading) {
      window.location.pathname = '/';
    }
  }, [ciam, page, sessionIsLoading]);

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  if (sessionIsLoading) {
    return (
      <div className="grid-container global_search">
        <div className="placeholder">
          <Loader size="large" />
        </div>
      </div>
    );
  }

  return (
    <Container maxContentWidth={isXy ? buildSize(gridSizes.gddsFullGrid) : '80rem'}>
      {isXy
        ? <Headline tag={Formats.h1} title="Order History" className="c-page-headline" />
        : <Title Format={TitleFormats.h1} title="Order History" pageHeadline />
      }
      <Row justify="center">
        <Col size={isXy ? [4, 8, 8] : [4, 8, 12]}>
          <p className={styles.paragraph}>
            <Translation id="web20_orderhistory_text" />
          </p>
          {isLoading && (
            <div className={styles.historyLoader}>
              <Loader />
            </div>
          )}
          {!isLoading && !hasError && pagedItems.length === 0 && (
            <div className={styles.message}>
              <Translation id="web20_orderhistory_noorders" />
            </div>
          )}
          {!isLoading && hasError && (
            <div className={styles.message}>
              <Message
                content={translate('web20_orderhistory_error')}
                closable={false}
                type="alert"
              />
            </div>
          )}
          {!hasError && (
            isXy ? <GddsOrdersAccordion data={pagedItems} ciam={ciam} />
            : (
              <Accordion
                allowMultipleExpanded
                allowZeroExpanded
                className={`${styles.orderHistory} c-accordion`}
              >
                {pagedItems.map((item) => (
                  <ProductDetailAccordionItem
                    key={item.id}
                    uuid={item.id}
                    title={
                      <span className={styles.accordionTitle}>
                        <span className={item.order ? styles.titleDate : styles.pendingOrder}>
                          <strong>
                            {item.id !== 'PENDING' ? (
                              format(new Date(item.date), dateFormat || 'yyyy-MM-dd')
                            ) : (
                              <Translation id="web20_orderhistory_pending_order" />
                            )}
                          </strong>
                        </span>
                        {item.id !== 'PENDING' && (
                          <small>
                            <Translation id="web20_orderhistory_no" /> {item.id}
                          </small>
                        )}
                      </span>
                    }
                  >
                    <div className="c-accordion__content">
                      <OrderHistoryDetails orderId={item.id} ciam={ciam} />
                    </div>
                  </ProductDetailAccordionItem>
                ))}
              </Accordion>
            )
          )}
          {hasLoadMore && (
            <div className={styles.historyLoadmore}>
              <LoadMoreButton handleLoadMore={handleLoadMore} />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default OrderHistoryContainer;
