import { Col, Container, Button, Row} from '@geberit/gdds';
import { useCallback } from 'react';

// styles
import styles from './checkoutIntro.module.scss';

// components
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { useCiamLogin } from 'utils/hooks/useCiam';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { buildSize, gridSizes } from 'utils/gridSize';

type CheckoutIntroViewProps = {
  onGuestClick: () => void;
  hasGuestButton: boolean;
}

export function CheckoutIntroView({
  onGuestClick,
  hasGuestButton,
}: Readonly<CheckoutIntroViewProps>) {
  const gigyaWindow = typeof window !== 'undefined' ? window['gigya'] : null;
  const language = useCurrentLanguage();
  const { login } = useCiamLogin(gigyaWindow);
  const translate = useTranslationFunction();

  const openLogin = useCallback(() => {
    const country = language.slice(language.length - 2);
    login(language, `${country}_Webshop`);
  }, [language, login]);

  return (
    <Container className={styles.checkoutIntro} maxContentWidth={buildSize(gridSizes.gddsFullGrid)}>
      <Row justify="center">
        <Col size={[4, 8, 8]}>
          <Headline
            isFlexItem
            tag='h1'
            title={translate('web20_checkout_intro_title')}
            subtitle={translate('web20_checkout_intro_subtitle')}
            className={styles.checkoutIntroHeadline}
            intro={translate('web20_checkout_intro_text')}
            introTextClassName={styles.checkoutIntroText}
          />
          <div className={styles.checkoutButtons}>
            {hasGuestButton && (
              <Button
                symbol="arrow_right"
                onClick={onGuestClick}
                className={styles.editButton}
              >
                {translate('web20_checkout_intro_button_guest')}
              </Button>
            )}
            <Button
              stylingType='primary'
              symbol="profile"
              onClick={openLogin}
            >
              {translate('web20_checkout_intro_button_login_register')}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
