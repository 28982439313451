import { useEffect } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import key from 'weak-key';
import { useDialogue, AspectRatio, Button, Typo, Col, Container, Row } from '@geberit/gdds';

// styles
import styles from './videocenter-channel.module.scss';

// types
import type { VideoTileProps } from './types';

// components
import ChannelMainVideo from './channel-main-video';
import { Translation } from 'components/Translation/Translation';
import VideocenterDropdown from './videocenter-dropdown';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { DialogueBody } from './dialogue-body';
import VideoTile from './video-tile';
import { Headline } from 'components/ContentElementsGdds/headline/headline';


// utils
import { useChannels } from './useChannels';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { buildSize, gridSizes } from 'utils/gridSize';
import { decodingContent } from 'utils/decodingContent';
import { useKoloOrTwyford } from 'utils/hooks/use-kolo-twyford';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useIsMobile } from 'components/App/SizeProvider';
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';
import { useXy } from 'utils/hooks/use-xy';

export interface VideocenterChannelProps {
  searchQueryChannelId: string;
  channels: any[];
  page: {
    dropdownLabel?: string;
    metaData: MetaData;
    currentLanguage: string;
  };
}

const skipFirst = (_: unknown, index: number): boolean => index >= 1;

export default function VideocenterChannel({
  searchQueryChannelId = '',
  channels,
  page: { metaData, currentLanguage },
}: Readonly<VideocenterChannelProps>) {
  const router = useRouter();
  const pathname = usePathname();
  const translate = useTranslationFunction();
  const { showDialogue, closeDialogue } = useDialogue();
  const { channel, firstVideo, loadable, sharedVideo, videos, handleLoadMore } = useChannels({
    currentLanguage,
    searchQueryChannelId,
    channels,
  });
  const isKoloOrTwyford = useKoloOrTwyford();
  const isMobile = useIsMobile();
  const params = useSearchParams();
  const videoId = String(params.get('video'));
  const isXy = useXy();

  useEffect(() => {
    if (videoId && videos?.length) {
      const { description, title, video } = videos.find((v) => v.video.videoId === videoId) || {};
      if (!video) return;
      showDialogue({
        type: 'custom',
        size: 'l',
        body: (
          <DialogueBody
            metaData={{ ...metaData, url: window.location.href, title }}
            isYoutubeVideo={video?.type === 'youtube'}
            videoId={video?.videoId ?? ''}
            title={decodingContent(video?.videoTitle)}
            text={decodingContent(description)}
            sharePageText={decodingContent(translate('web20_social_share_headline'))}
          />
        ),
        titleLabel: decodingContent(title),
        noFooterButton: true,
        fullscreen: true,
        onClose: () => {
          const { video: _, ...restQuery } = Object.fromEntries(params);
          const updatedQuery = new URLSearchParams(restQuery);
          router.replace(`${pathname}?${updatedQuery.toString()}`, { scroll: false });
        },
      });
    } else if (!videoId) {
      closeDialogue();
    }
  }, [metaData, params, pathname, router, translate, videoId, videos, showDialogue, closeDialogue]);

  function getVideoOnclickHandler({ video: { videoId } }: VideoTileProps) {
    return () => {
      const writeQuery = new URLSearchParams(params.toString());
      writeQuery.set('video', videoId);
      router.replace(`${pathname}?${writeQuery.toString()}`, { scroll: false });
    };
  }

  return (
    <Container
      maxContentWidth={buildSize(gridSizes.gddsFullGrid)}
      className={styles.videoChannelWrapper}
    >
      <Row className={styles.titleRow}>
        <Col size={[0, 0, 2]}></Col>
        <Col size={[4, 8, 8]}>
          <Headline
            isFlexItem
            title={translate('web20_videocenter_channel')}
            subtitle={channel ? channel.title : ''}
            tag={Formats.h1}
          />
        </Col>
        <Col size={[0, 0, 2]}></Col>
      </Row>
      <Row className={styles.mainVideoRow}>
        <Col size={[4, 5, 7]}>
          {firstVideo && (
            <AspectRatio ratio={'16:9'}>
              <ChannelMainVideo
                video={firstVideo.video}
                title={decodingContent(firstVideo.title)}
                key={key(firstVideo.video)}
              />
            </AspectRatio>
          )}
        </Col>
        <Col size={[4, 3, 5]}>
          {firstVideo?.title && (
            <Typo tag={isXy ? "h2" : "h3"} variant="h3" className={classNameBuilder(isKoloOrTwyford && styles.headlineKolo)}>
              {decodingContent(firstVideo.title)}
            </Typo>
          )}
          {firstVideo?.description && (
            <InnerHtml
              className={styles.copyText}
              as="div"
              content={decodingContent(firstVideo.description)}
              isGdds
            />
          )}
        </Col>
      </Row>
      <Row className={styles.dropdownRow}>
        <Col size={[4, 4, 3]}>
          <VideocenterDropdown searchQueryChannelId={searchQueryChannelId} channels={channels} />
        </Col>
        <Col size={[0, 4, 9]}></Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.videocenterChannel}>
            {videos
              ?.filter(skipFirst)
              .map((videoProps: VideoTileProps) => (
                <VideoTile
                  key={key(videoProps.video)}
                  onClick={getVideoOnclickHandler(videoProps)}
                  video={videoProps}
                  screenSize="small"
                  open={sharedVideo === videoProps.title}
                />
              ))}
          </div>
        </Col>
        <Col>
          {loadable && (
            <div className={styles.loadmoreButton} key="button-item-wrapper">
              <Button key="button" symbol="Reload" onClick={handleLoadMore} stretched={isMobile}>
                <Translation id="web20_more_results" key="translation" />
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
