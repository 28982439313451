import { useState, useEffect, useRef, useId, useCallback } from 'react';
import { useSelector } from 'react-redux';

// components
import MiVideoContainerView from './MiVideoContainerView';
import { Translation } from 'components/Translation/Translation';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { getStringSpecificId } from 'utils/slug';
import { isClient } from 'utils/environment';
import hashCode from 'utils/hash';
import { videoAutoplaySelector } from 'utils/selectors/globalsSelectors';
import { useTracking } from 'utils/hooks/useTracking';
import { trackMIVideo } from './trackingActions';
import { useUCConsent } from 'features/cookie-consent';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { Log } from 'services/log';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { isEmpty } from 'utils/is-empty';

export interface MiVideoContainerProps {
  content: Record<string, any>;
  className: string;
  openModal?: () => void;
  hasModal?: boolean;
  isModal?: boolean;
  hasError?: boolean;
  isVideoContent?: boolean;
  isSectionReference?: boolean;
  isHeroImageForegroundVideoPlayer?: boolean;
}

export function MiVideoContainer({
  content,
  content: { video, autoplay, buttonText, videoVariant },
  isModal = false,
  hasError = false,
  hasModal = false,
  isVideoContent = false,
  isSectionReference = false,
  className,
  openModal,
  isHeroImageForegroundVideoPlayer,
}: Readonly<MiVideoContainerProps>) {
  const [displayVideo, setDisplayVideo] = useState(false);
  const [hasVideoError, setHasVideoError] = useState(false);
  const [paused, setPaused] = useState(true);
  const [isPlayerInitialized, setIsPlayerInitialized] = useState(false);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const autoplayTriggered = useRef(false);
  const domId = useRef<string | null>(null);
  const mounted = useRef(false);
  const videoTrackRef = useRef(0);
  const ga4VideoTrackRef = useRef(0);
  const videoPlayButtonLabel = useTranslationByKey('web20_video_play_button') || 'Play Video';
  const globalVideoAutoplay = useSelector(videoAutoplaySelector) ?? false;
  const ucConsent = useUCConsent();
  const isMobile = useIsMobile();
  const id = useId();
  const track = useTracking();
  const { trackMI24Video } = useTracking2();

  useEffect(() => {
    // reset domId if domId is already set and videoId has changed
    if (domId.current) {
      domId.current = `video-${hashCode(getStringSpecificId('video-content', video?.videoId, id))}`;
      setIsPlayerInitialized(false);
    }
  }, [id, video?.videoId]);

  /**
   * Initialize the MI24 video depending on autoplay
   */
  const initVideoPlayer = useCallback(() => {
    if (isPlayerInitialized) {
      return;
    }

    try {
      window.VideoPlayer.Collection.addPlayerById(domId.current as string, {
        success: (playerApi) => {
          if (
            autoplay === 'yes' ||
            (isModal && globalVideoAutoplay && !isHeroImageForegroundVideoPlayer)
          ) {
            playerApi.toggleMute();
            playerApi.play();
          }
          playerApi.registerEventListener('pause', () => {
            handleVideoState(true);
          });
          playerApi.registerEventListener('play', () => {
            handleVideoState(false);
          });
          if (!isEmpty(content) && !isEmpty(video)) {
            trackMIVideo(videoTrackRef, playerApi, null, video?.videoId, track);
            trackMI24Video(
              ga4VideoTrackRef,
              playerApi,
              video.title ?? content.title,
              video?.videoId,
            );
          }
        },
      });

      setIsPlayerInitialized(true);
    } catch (e) {
      if (mounted.current) setHasVideoError(true);
    }
  }, [autoplay, content, globalVideoAutoplay, isModal, isPlayerInitialized, track, video]);

  useEffect(() => {
    mounted.current = true;
    if (!domId.current) {
      domId.current = `video-${hashCode(getStringSpecificId('video-content', video?.videoId, id))}`;
    }

    if (ucConsent.Comfort) {
      const mi24Script = document.getElementById('mi24-js-script');

      if (!mi24Script) {
        const script = document.createElement('script');
        script.src = `https://e.video-cdn.net/v2/embed.js`;
        script.id = 'mi24-js-script';
        script.async = true;
        script.addEventListener('load', () => {
          script.setAttribute('data-loaded', 'true');
          if (mounted.current) setPlayerLoaded(true);
        });
        document.head.appendChild(script);
      } else if (mounted.current && !playerLoaded) {
        if (mi24Script.getAttribute('data-loaded')) {
          setPlayerLoaded(true);
        } else {
          mi24Script.addEventListener('load', () => {
            if (mounted.current) setPlayerLoaded(true);
          });
        }
      }
    } else {
      setIsPlayerInitialized(false);
      try {
        const player = window.VideoPlayer.Collection.getPlayerById(domId.current);

        // seconds
        const time = player.getCurrentTime();
        player.pause();

        // miliseconds needed
        player.rewind(time * 1000);
        player.pause();
      } catch {}
    }

    return () => {
      if (domId.current && isClient() && !hasModal) {
        try {
          const player = window.VideoPlayer.Collection.getPlayerById(domId.current);
          if (player?.getApi()) {
            window.VideoPlayer.Collection.removePlayerById(domId.current);
          }
        } catch (e) {
          Log.error('Failed to remove video player', e);
        }
      }
      mounted.current = false;
    };
  }, [hasModal, id, playerLoaded, ucConsent.Comfort, video?.videoId]);

  useEffect(() => {
    if (playerLoaded && !hasModal && window.VideoPlayer && window.VideoPlayer.Collection) {
      initVideoPlayer();
    }
  }, [hasModal, initVideoPlayer, playerLoaded, ucConsent.Comfort]);

  useEffect(() => {
    if (!isPlayerInitialized) return;

    const timeout = setTimeout(() => {
      const videoWrapper = document.querySelector('.video-wrapper');
      if (videoWrapper) {
        const miPlayers = videoWrapper.querySelectorAll('.mi-player');
        const [_, ...restPlayers] = Array.from<Element>(miPlayers);
        for (const player of restPlayers) {
          player.remove();
        }
      }
    }, 200);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isPlayerInitialized]);

  // we want to return if video is empty
  if (isEmpty(video)) {
    return null;
  }

  /**
   * Toggle video paused state
   *
   * @param newPaused  Boolean
   */
  const handleVideoState = (newPaused) => {
    if (mounted.current) setPaused(newPaused);
  };

  /**
   * Play the video and show the video if hidden
   *
   * @param e Event
   */
  const playVideo = (e?: any) => {
    if (mounted.current) setDisplayVideo(true);
    e?.preventDefault();

    if (ucConsent.Comfort && isClient() && domId.current) {
      try {
        const player = VideoPlayer.Collection.getPlayerById(domId.current);

        player.play();
      } catch (e) {
        // This is only the workaround if the video player has not yet been initialised.
        // If the video has not been initialised, there is no external access
        // to the player api (play, pause, etc.).
        // But if the player has already been initialised, the upper try block works.
        const playButon = document.querySelector<HTMLButtonElement>(`#${domId.current} .mi-cover`);
        if (playButon) {
          playButon.click();
        }
      }
    }
  };

  if (!autoplayTriggered.current && autoplay === 'yes') {
    setTimeout(() => {
      playVideo();
      autoplayTriggered.current = true;
    }, 1000);
  }

  let previewImage = '';
  if (!isEmpty(video?.videoPreviewImage)) {
    previewImage =
      isMobile === true
        ? video.videoPreviewImage.mobile
        : video.videoPreviewImage.desktop || video.videoPreviewImage.mobile;
  }

  const isAutoPlay = autoplay === 'yes' || isModal;
  const previewImageDisplay = !displayVideo && !isModal && !isAutoPlay ? 'hide' : '';

  if (
    (hasVideoError && !previewImageDisplay && isAutoPlay) ||
    (hasVideoError && videoVariant) ||
    (hasError && isModal) ||
    (hasVideoError && isModal)
  ) {
    return <Translation id="web20_video_is_not_available" />;
  }

  return (
    <MiVideoContainerView
      hasError={hasError}
      className={className}
      content={content}
      displayVideo={!hasModal && displayVideo}
      previewImage={previewImage}
      previewImageDisplay={hasModal ? undefined : previewImageDisplay}
      videoPlayButtonLabel={videoPlayButtonLabel}
      hasModal={hasModal}
      playVideo={hasModal ? undefined : playVideo}
      buttonText={buttonText}
      paused={paused}
      domId={domId.current}
      isModal={isModal}
      openModal={openModal}
      isSectionReference={!!isSectionReference}
      isVideoContent={isVideoContent}
    />
  );
}
