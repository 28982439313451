import React, { useRef, useState } from 'react';
import Link from 'next/link';
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown';
import key from 'weak-key';

// components
import { Translation } from 'components/Translation/Translation';

// types
import type { Channel } from 'components/ContentElementsGdds/video-channel/types';

// utils
import { decodingContent } from 'utils/decodingContent';
import { useOutsideClick } from 'utils/hooks/use-outside-click';

interface VideocenterDropdownProps {
  channels: Channel[];
  searchQueryChannelId: string;
}

function VideocenterDropdown({
  channels,
  searchQueryChannelId,
}: Readonly<VideocenterDropdownProps>) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const replaceSlashInPathFnc = (pathname) =>
    pathname.slice(-1) === '/' ? pathname.replace(/\/$/, '') : pathname;
  const currentChannel = channels.find((item) => item.id === searchQueryChannelId);
  const dropdownTitle = currentChannel && `${currentChannel.title} - `;
  const [isExpanded, setIsExpanded] = useState(false);

  useOutsideClick(wrapperRef, () => setIsExpanded(false));

  return (
    <div ref={wrapperRef}>
      <Dropdown className="c-video-center__channel__selector" active={isExpanded}>
        <DropdownTrigger
          className="c-video-center__channel__selector__trigger"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {decodingContent(dropdownTitle)}
          <Translation id="web20_videocenter_channel_change" />
        </DropdownTrigger>
        <DropdownContent className="c-video-center-channel__selector__content">
          <ul>
            {channels
              .filter((c) => c.id !== searchQueryChannelId)
              .map((channel) => (
                <li key={key(channel)}>
                  <Link
                    href={`${replaceSlashInPathFnc(channel.url)}/?channel=${channel.id}`}
                    key={`${channel.reactKey}-routerlink`}
                    onClick={() => setIsExpanded(false)}
                  >
                    {decodingContent(channel.title)}
                  </Link>
                </li>
              ))}
          </ul>
        </DropdownContent>
      </Dropdown>
    </div>
  );
}

export default VideocenterDropdown;
